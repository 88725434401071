import Cookie from 'js-cookie';
module.exports = {
    getToken: function () {
        return Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN) ? atob(Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN)) : false
    },
    getHeaders: function () {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.getToken()
        }
    },
    getWishlists: async function (myNewport) {
        try {
            let response = await fetch(myNewport + '/api/wishlist', {
                method: 'GET',
                headers: this.getHeaders(),
            });

            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    getWeddingList: async function (myNewport) {
        try {
            let response = await fetch(myNewport + '/api/wishlist/wedding-list', {
                method: 'GET',
                headers: this.getHeaders(),
            });

            let responseJson = await response.json();

            if(response.status != 200 || !responseJson.data) {
                return { status: 'error', data: {} };
            }

            return { status: 'success', data: responseJson.data };
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    getWishlistProducts: async function(myNewport, wishlistId) {
        try {
            let response = await fetch(myNewport + '/api/wishlist/' + wishlistId, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    getWishlistProductIds: async function(myNewport) {
        try {
            let response = await fetch(myNewport + '/api/wishlist/product-ids', {
                method: 'GET',
                headers: this.getHeaders(),
            });

            let responseJson = await response.json();

            if(response.status !== 200) {
                return [];
            }

            return responseJson?.ids || [];
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    addWishlistProduct: async function(myNewport, wishlistId, productId) {
        try {
            let response = await fetch(myNewport + '/api/wishlist/' + wishlistId + '/item', {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    product_id: productId,
                    qty: 1
                })
            });

            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    removeWishlistProduct: async function(myNewport, wishlistId, productId) {
        try {
            let response = await fetch(myNewport + '/api/wishlist/' + wishlistId + '/item/' + productId, {
                method: 'DELETE',
                headers: this.getHeaders()
            });

            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    createWishlist: async function(myNewport, { name, isWedding, text, location, date }) {
        try {
            let response = await fetch(myNewport + '/api/wishlist', {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    name,
                    type: isWedding ? 'wedding' : 'standard',
                    text,
                    location,
                    date
                })
            });

            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'UnknownWishlistError');
        }
    },
    updateDiscoveryKey: async function(myNewport, discoveryKey) {
        try {
            let response = await fetch(myNewport + '/api/account/discovery-key', {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    discovery_key: discoveryKey,
                })
            });

            if (!response.ok) {
                console.error(`Couldn't update discovery key. [${response.status}]`)
            }
        } catch (e) {
            console.error('Couldn\'t update discovery key')
        }
    }
}
